import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import "../../scss/reports/select-report.scss";
import { get_tekstenbieb, delete_tekstenbieb } from "../../functions/api";

import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

const permissions = JSON.parse(localStorage.getItem("permissions"));

function Administration() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    field: "tekstenbiebid",
    order: "asc",
  });

  const [openActionsId, setOpenActionsId] = useState(null);

  const thStyle =
    "px-4 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-lg font-semibold text-gray-600 cursor-pointer";
  const tdStyle = "px-4 py-3 border-b border-gray-200 text-lg";

  useEffect(() => {
    get_tekstenbieb()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        const filteredData = data.result.filter((item) => item.labelnaam);

        setData(filteredData);
        setFilteredData(filteredData);
        handleSort(sortOrder.field, sortOrder.order, filteredData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const deleteBieb = (e, object) => {
    e.stopPropagation();

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je de tekstenbieb wil verwijderen?",
        text: "Alle onderliggende teksten worden ook verwijderd. Dit kan niet ongedaan worden gemaakt.",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        delete_tekstenbieb(object.tekstenbiebid)
          .then(() => {
            setData(data.filter((item) => item.tekstenbiebid !== object.tekstenbiebid));
            setFilteredData(filteredData.filter((item) => item.tekstenbiebid !== object.tekstenbiebid));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    const lowercasedFilter = term.toLowerCase();
    const filtered = data.filter(
      (item) =>
        item.labelnaam.toLowerCase().includes(lowercasedFilter) ||
        item.tekstenbiebid.toString().includes(lowercasedFilter)
    );
    handleSort(sortOrder.field, sortOrder.order, filtered);
  };

  const handleSort = (field, order, dataToSort = filteredData) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      const valueA =
        field === "tekstenbiebid" ? Number(a[field]) : a[field].toLowerCase();
      const valueB =
        field === "tekstenbiebid" ? Number(b[field]) : b[field].toLowerCase();
      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
    setSortOrder({ field, order });
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSortOrder({ field: "tekstenbiebid", order: "asc" });
    handleSort("tekstenbiebid", "asc");
  };

  const handleClickOutside = (event) => {
    if (openActionsId && !event.target.closest(`#action-menu-${openActionsId}`)) {
      setOpenActionsId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openActionsId]);

  const renderTableRows = () => {
    if (filteredData.length > 0) {
      return filteredData.map((object) => (
        <tr
          key={object.tekstenbiebid}
          className="select-report-row select-report-object hover:bg-gray-100 cursor-pointer transition-all duration-150"
          onClick={() => {
            navigate(`/administration/edit/${object.tekstenbiebid}`, {
              state: {
                tekstenbiebid: object.tekstenbiebid,
                labelnaam: object.labelnaam,
                teksten: object.teksten,
              },
            });
          }}
        >
          <td className={tdStyle}>{object.tekstenbiebid}</td>
          <td className={tdStyle}>{object.labelnaam}</td>
          <td className={tdStyle}>{object.teksten.length > 1 ? object.teksten.length + ' teksten' : object.teksten.length < 1 ? 'Geen teksten' : object.teksten.length + ' tekst'}</td>
          <td className={tdStyle}>
            <div className="relative">
              <button
                className="hover:bg-gray-200 rounded-lg p-1.5"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenActionsId(
                    openActionsId === object.tekstenbiebid
                      ? null
                      : object.tekstenbiebid
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-dots"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                  <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                  <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                </svg>
              </button>
              {openActionsId === object.tekstenbiebid && (
                <div
                  id={`action-menu-${object.tekstenbiebid}`}
                  className="absolute top-8 right-8 bg-white border border-gray-200 rounded-lg"
                >
                  <div
                    className="flex gap-2 text-base hover:bg-red-500/20 hover:text-red-500 transition-all duration-150 px-2.5 py-1.5 rounded-lg"
                    onClick={(e) => deleteBieb(e, object)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-trash"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 7l16 0" />
                      <path d="M10 11l0 6" />
                      <path d="M14 11l0 6" />
                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                    </svg>
                    Verwijderen
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan="4" className="px-4 py-2 text-center">
          Geen teksten gevonden
        </td>
      </tr>
    );
  };

  return (
    <div className="select-report-container-main">
      <Nav page="Administratie" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="select-report-container p-4">
          <h1 className="text-2xl font-semibold mb-4 ml-10 mt-10">
            Tekstenbieb
          </h1>
          <div className="px-10 mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Zoek op bieb nummer of label..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border rounded-md shadow-sm w-1/3"
            />
            <div className="flex space-x-4">
              <button
                onClick={() =>
                  handleSort(
                    sortOrder.field,
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op{" "}
                {sortOrder.field === "tekstenbiebid"
                  ? "Bieb nr."
                  : "Label"}{" "}
                {sortOrder.order === "asc" ? "▲" : "▼"}
              </button>
              <button
                onClick={resetFilters}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white"
              >
                Reset Filters
              </button>
              <Link
                to="/administration/create"
                className="px-4 py-2 border rounded-md shadow-sm text-white no-underline"
                style={{ backgroundColor: "#060a24" }}
              >
                Aanmaken
              </Link>
            </div>
          </div>
          <div className="select-report-container-1">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className={thStyle}>Bieb nr.</th>
                    <th className={thStyle}>Label</th>
                    <th className={thStyle}>Aantal</th>
                    <th className={thStyle}></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={4} className="px-4 py-2 text-center">
                        Laden...
                      </td>
                    </tr>
                  ) : (
                    renderTableRows()
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Administration;
