import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Swal from "sweetalert2";

import "../../scss/reports/select-report.scss";
// Get Reports
import { get_all_rapporten } from "../../functions/api";
// Navigation
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

// permissions
const permissions = JSON.parse(localStorage.getItem("permissions"));

function Reports() {
  const navigate = useNavigate();

  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    field: "datumstart",
    order: "desc",
  });

  const thStyle =
    "px-4 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-lg font-semibold text-gray-600 cursor-pointer";
  const tdStyle = "px-4 py-3 border-b border-gray-200 text-lg";

  useEffect(() => {
    get_all_rapporten()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        setReports(data.rapportage_object);
        setFilteredReports(data.rapportage_object);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (permissions.viewPermissions.showRapportages === "0") {
          Swal.fire({
            title: "Geen toegang!",
            text: "U wordt terug gestuurd naar de vorige pagina.",
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            navigate(-1);
          });
        }
      });
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const lowercasedFilter = term.toLowerCase();
    const filteredData = reports.filter(
      (item) =>
        item.rapport_info.klantnaam.toLowerCase().includes(lowercasedFilter) ||
        item.rapportid.includes(lowercasedFilter) ||
        item.rapport_info.objectnaam.toLowerCase().includes(lowercasedFilter)
    );
    handleSort(sortOrder.field, sortOrder.order, filteredData);
  };

  const handleSort = (field, order, data = filteredReports) => {
    const sortedData = [...data].sort((a, b) => {
      if (field === "datumstart") {
        const dateA = new Date(a.rapport_info[field]);
        const dateB = new Date(b.rapport_info[field]);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else if (field === "klantnaam") {
        const nameA = a.rapport_info.klantnaam.toLowerCase();
        const nameB = b.rapport_info.klantnaam.toLowerCase();
        if (nameA < nameB) return order === "asc" ? -1 : 1;
        if (nameA > nameB) return order === "asc" ? 1 : -1;
        return 0;
      }
    });
    setFilteredReports(sortedData);
    setSortOrder({ field, order });
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSortOrder({ field: "datumstart", order: "desc" });
    setFilteredReports(reports);
  };

  console.log(reports);

  const reportList = () => {
    if (filteredReports.length > 0) {
      return filteredReports.map((report) => (
        <tr
          key={report.rapportid}
          className="hover:bg-gray-100 transition-colors duration-200"
          onClick={() => {
            navigate("/reports/edit_report", {
              state: { rapportId: report.rapportid },
            });
          }}
        >
          <td className={tdStyle}>{report.rapportid}</td>
          <td className={tdStyle}>{report.rapport_info.klantnaam}</td>
          <td className={tdStyle}>{report.rapport_info.objectnaam}</td>
          <td className={tdStyle}>
            <Moment locale="nl" format="DD-MMM YYYY">
              {report.rapport_info.datumstart === "0000-00-00 00:00:00" ||
              report.rapport_info.datumstart === null
                ? new Date()
                : report.rapport_info.datumstart}
            </Moment>
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan="4" className="px-4 py-2 text-center">
          Geen rapporten gevonden
        </td>
      </tr>
    );
  };

  return (
    <div className="select-report-container-main">
      <Nav page="Rapportages" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="select-report-container p-4">
          <h1 className="text-2xl font-semibold mb-4 ml-10 mt-10">
            Rapportages
          </h1>
          <div className="px-10 mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Zoek op naam..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border rounded-md shadow-sm w-1/3"
            />
            <div className="flex space-x-4">
              <button
                onClick={() =>
                  handleSort(
                    "datumstart",
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op Datum{" "}
                {sortOrder.field === "datumstart" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </button>
              <button
                onClick={() =>
                  handleSort(
                    "klantnaam",
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op Naam{" "}
                {sortOrder.field === "klantnaam" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </button>
              <button
                onClick={resetFilters}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white"
              >
                Reset Filters
              </button>
              <Link
                to="/reports/select_inspection"
                className="px-4 py-2 border rounded-md shadow-sm text-white"
                style={{ backgroundColor: "#060a24" }}
              >
                Aanmaken
              </Link>
            </div>
          </div>
          <div className="select-report-container-1">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className={thStyle}>Rapport nr.</th>
                    <th className={thStyle}>Klantnaam</th>
                    <th className={thStyle}>Objectnaam</th>
                    <th className={thStyle}>Datum inspectie</th>
                  </tr>
                </thead>
                <tbody>
                  {loading === true ? (
                    <tr>
                      <td colSpan={4} className="px-4 py-2 text-center">
                        Laden...
                      </td>
                    </tr>
                  ) : (
                    reportList()
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
