//global variables
// const expressBaseUrl = "https://api-expr.ezinspect.nl/";
// const expressBaseUrl = "http://localhost:3001/";
const expressBaseUrl = "https://api-ezinspect.jitso-dev.nl/";
// const oldApiBaseUrl = "https://etf.jitso.nl/api/v2/";
const apiBaseUrl = "https://ezinspect-api.jitso-dev.nl/api/v2/";

// import AsyncStorage from '@react-native-community/async-storage';

export async function get_inspecties() {
  return fetch(`${expressBaseUrl}get_all_inspecties`, {
    method: "GET",

    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [get inspecties]");
    });
}
export async function get_one_inspection(id) {
  return fetch(`${expressBaseUrl}get_single_inspection/${id}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [get inspecties]");
    });
}

export async function update_inspectie(data) {
  return fetch(`${expressBaseUrl}update_inspectie`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [update inspectie]");
    });
}

// export async function get_active_inspecties() {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/inspecties/0/0', {
//         method: 'GET',
//         headers: {
//             Accept: 'application/x-www-form-urlencoded',
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//             alert("Oeps.. dat ging even mis. [get inspecties]")

//         });

// }

// export async function heractivate_inspectie(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/inspecties/' + id, {
//         method: 'PUT',
//         headers: {
//             Accept: 'application/x-www-form-urlencoded',
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//             alert("Oeps.. dat ging even mis. [get inspecties]")

//         });

// }

// export async function get_afspraken() {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/afspraken', {
//         method: 'GET',
//         headers: {
//             Accept: 'application/x-www-form-urlencoded',
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//             alert("Oeps.. dat ging even mis. [get afspraken]")

//         });

// }

// export async function get_klanten() {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/klant', {
//         method: 'GET',
//         headers: {
//             Accept: 'application/x-www-form-urlencoded',
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//             alert("Oeps.. dat ging even mis. [get klanten]")

//         });
// }

export async function upload_image(file) {
  // var access_token = await AsyncStorage.getItem("access_token");

  const formData = new FormData();
  formData.append("image", file);
  return fetch(`${apiBaseUrl}upload`, {
    method: "POST",
    // credentials: "include",
    body: formData,
    // headers: {
    //     'Authorization': 'Bearer 3223eb039cd4db7f29150c3e94125f07cdf8ceab'
    // }
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      alert(JSON.stringify(error));
    });
}

export async function register(form_data) {
  const formData = new FormData();
  formData.append("form_data", form_data[0]);
  return (
    fetch(`${expressBaseUrl}register`, {
      method: "POST",

      body: JSON.stringify(form_data[0]),
      // mode: 'cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // .then((response) => { return response.json() })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      })
  );
}

export async function checkIfPaymentIdentificationIsValid(
  paymentIdentification
) {
  return fetch(
    `${expressBaseUrl}check_payment_identification?payment_identification=${paymentIdentification}`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

// export async function insert_inspection(inspectie_object) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/inspecties', {
//         method: 'POST',
//         body: JSON.stringify(inspectie_object),
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//             alert(error.message)

//         });

// }

// export async function insert_customer(object_klant) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/klant', {
//         method: 'POST',
//         body: JSON.stringify(object_klant),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function insert_object(object_object) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/ObjectKlant', {
//         method: 'POST',
//         body: JSON.stringify(object_klant),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function insert_tekst(object_tekst) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/TekstenBieb', {
//         method: 'POST',
//         body: JSON.stringify(object_tekst),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function get_all_teksten() {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/TekstenBieb', {
//         method: 'GET',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function insert_instrument(object_instrument) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Meetapparatuur', {
//         method: 'POST',
//         body: JSON.stringify(object_instrument),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

export async function get_all_instrumenten() {
  return fetch(`${expressBaseUrl}get_all_instrumenten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function get_all_objecten() {
  return fetch(`${expressBaseUrl}get_all_objecten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.json();
    });
}

export async function get_all_rapporten() {
  return fetch(`${expressBaseUrl}get_all_rapporten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}
export async function get_one_rapport(id) {
  return fetch(`${expressBaseUrl}get_one_rapport/` + id, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function show_permissions() {
  return fetch(`${expressBaseUrl}show_permissions`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return console.error(error);
    });
}

export async function show_member_permissions(id) {
  console.log();
  return fetch(`${expressBaseUrl}show_member_permissions/` + id, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return console.error(error);
    });
}

export async function update_permissions(data) {
  console.log();
  return fetch(`${expressBaseUrl}update_permissions`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
}

// export async function insert_objectfunctie(object_functie) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/ObjectFunctie', {
//         method: 'POST',
//         body: JSON.stringify(object_functie),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function insert_aansluitgegevens(object_aansluitgegeven) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Aansluitgegevens', {
//         method: 'POST',
//         body: JSON.stringify(object_aansluitgegeven),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function insert_normen(object_norm) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Normen', {
//         method: 'POST',
//         body: JSON.stringify(object_norm),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

export async function insert_rapportage(object_rapportage) {
  return fetch(`${expressBaseUrl}insert_rapportage`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: object_rapportage,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}
export async function update_rapportage(object_rapportage) {
  return fetch(`${expressBaseUrl}update_rapportage`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: object_rapportage,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

// export async function get_soort_inspecties() {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/SoortInspecties', {
//         method: 'GET',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

export async function insert_tekst(object_tekst) {
  return fetch(`${expressBaseUrl}insert_tekst`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object_tekst),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_tekst(id) {
  return fetch(`${expressBaseUrl}delete_tekst/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function get_all_teksten() {
  return fetch(`${expressBaseUrl}get_teksten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function get_tekstenbieb(id = null) {
  const url = id
    ? `${expressBaseUrl}get_tekstenbieb/${id}`
    : `${expressBaseUrl}get_tekstenbieb`;

  return fetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function delete_tekstenbieb(id) {
  return fetch(`${expressBaseUrl}delete_tekstenbieb/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

// export async function get_all_tekstenbieb() {
//   return fetch(`${expressBaseUrl}get_tekstenbieb`, {
//     method: "GET",
//     credentials: "include",
//   })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// }

// export async function get_labels_all() {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Labels', {
//         method: 'GET',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

export async function get_all_aansluitgegevens() {
  return fetch(`${expressBaseUrl}get_all_aansluitgegevens`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.json();
    });
}

export async function get_all_normen() {
  return fetch(`${expressBaseUrl}get_all_normen`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.json();
    });
}

// export async function delete_customer(id) {
//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Klant/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_object(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/ObjectKlant/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_instrument(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Meetapparatuur/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_soortenfuncties(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/ObjectFunctie/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_aansluitgegevens(id) {
//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Aansluitgegevens/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_norm(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Normen/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_subtekst(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/TekstenBiebTekst/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_afspraak(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Afspraken/' + id, {
//         method: 'DELETE',
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function delete_inspection(id) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/inspecties/' + id, {
//         method: 'DELETE',
//         headers: {
//             Accept: 'application/x-www-form-urlencoded',
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//             alert("Oeps.. dat ging even mis. [delete inspecties]")

//         });

// }

export async function delete_rapportage(id) {
  return fetch(`${expressBaseUrl}delete_rapportage/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [delete inspecties] \n" + error);
    });
}

// export async function insert_tekst_label(object_tekst) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/Labels', {
//         method: 'POST',
//         body: JSON.stringify(object_tekst),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,
//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function report_gevaarlijke_situatie(object_gevaarlijke_situatie) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/GevaarlijkeSituatie', {
//         method: 'POST',
//         body: JSON.stringify(object_gevaarlijke_situatie),
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + access_token,
//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

// export async function upload_sign(object_upload_sign) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/UploadSign', {
//         method: 'POST',
//         body: JSON.stringify(object_upload_sign),
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,

//         }
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

export async function login(username, password) {
  const login_data = {
    username: username,
    password: password,
  };
  return fetch(`${expressBaseUrl}login`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(login_data),
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
}

export async function logoutExpress() {
  return fetch(`${expressBaseUrl}logout`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function check_auth() {
  return fetch(`${expressBaseUrl}check_auth`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {});
}

export function check_forget_password_id(id) {
  if (id == null) {
    return;
  }
  return fetch(`${expressBaseUrl}check_forget_password_id`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {});
}

export function get_user() {
  return fetch(`${expressBaseUrl}get_user`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
}

export function subscribe_package(package_id) {
  return fetch(`${expressBaseUrl}subscribe`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ package_id: package_id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export function resetPassword(password1, password2, id) {
  return fetch(`${expressBaseUrl}resetPassword`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      password1: password1,
      password2: password2,
      id: id,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export function update_subscribe_package(package_id) {
  return fetch(`${expressBaseUrl}update_subcription`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ package_id: package_id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export function forget_password(email) {
  return fetch(`${expressBaseUrl}forget_password`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email: email }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export async function check_invite_link(id, token) {
  return fetch(`${expressBaseUrl}check_invite_link/${id}/${token}`, {
    method: "GET",

    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export async function register_team_member(id, token, data) {
  return fetch(`${expressBaseUrl}register_team_member/${id}/${token}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function invite_member(invitation_data) {
  return fetch(`${expressBaseUrl}invite_member`, {
    method: "POST",

    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invitation_data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function disable_member(object) {
  return fetch(`${expressBaseUrl}disable_member`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function update_member(object) {
  return fetch(`${expressBaseUrl}update_member`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function create_customer(object) {
  return fetch(`${expressBaseUrl}klant`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function get_all_customers() {
  return fetch(`${expressBaseUrl}klant`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

// export async function get_teksten_rapport(soort_inspectie) {

//     var access_token = await AsyncStorage.getItem("access_token");

//     return fetch('https://etf.jitso.nl/api/v2/RapportageTeksten/' + soort_inspectie, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + access_token,
//         },
//     }).then((response) => { return response.json() })
//         .catch((error) => {

//         });

// }

export async function get_bijlagen(id) {
  return fetch(`${expressBaseUrl}get_bijlagen/${id}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function get_default_bijlagen() {
  return fetch(`${expressBaseUrl}get_default_bijlagen`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function create_bijlagen(data) {
  return fetch(`${expressBaseUrl}create_bijlagen`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
}
