import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import "../../scss/reports/select-report.scss";

// Create tekst
import { insert_tekst, delete_tekst } from "../../functions/api";

//Navigation
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

export default function Create() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [data, setData] = useState(location.state ?? {
    tekstenbiebid: 0,
    labelnaam: "",
    teksten:  [],
  });
  
  const saveData = () => {
    insert_tekst(data)
      .then((response) => {
        console.log(response);
      })
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addText = () => {
    setData({
      ...data,
      teksten: [
        ...data.teksten,
        {
          biebtekstid: null,
          omschrijving: "",
          steekwoord: "",
        },
      ],
    });
  }

  const removeText = (index) => {
   const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je de tekst wil verwijderen?",
        text: "Dit kan niet ongedaan worden gemaakt",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (data.teksten[index].biebtekstid) {
          delete_tekst(data.teksten[index].biebtekstid)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }

        setData({
          ...data,
          teksten: data.teksten.filter((t, i) => i !== index),
        });
      }
    });
  }

  const updateText = (index, key, value) => {
    setData({
      ...data,
      teksten: data.teksten.map((t, i) => i === index ? { ...t, [key]: value } : t),
    });
  }
  
  return (
    <div className="select-report-container-main">
      <Nav page="Administratie" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="ml-[37px] md:ml-[163px] lg:ml-[189px] mt-[62px] w-full p-6 text-black">
          <h1 className="text-2xl font-semibold">
            Nieuwe tekst
          </h1>
          <hr className="hr2" />
          <div className="flex flex-col gap-4 mb-6">
            <div>
              <label htmlFor="labelnaam" className="block mt-2">
                Label
              </label>
              <input
                type="text"
                id="labelnaam"
                value={data.labelnaam}
                onChange={(e) => setData({ ...data, labelnaam: e.target.value })}
                className="rounded-md border border-gray-400 w-full p-2 mt-2 max-w-[300px]"
              />
            </div>
            <button className="border-3 border-black border-dashed px-4 py-2 rounded-md max-w-64" onClick={addText}>
              Nieuwe tekst
            </button>
          </div>
          {data.teksten.map((tekst, index) => (
            <div key={index} className="flex items-center gap-4">
              <div className="flex-grow grid grid-cols-2 items-center gap-4">
                <div>
                  <label htmlFor={`omschrijving-${index}`} className="block mt-2">
                    Tekst
                  </label>
                  <textarea
                    type="text"
                    id={`omschrijving-${index}`}
                    value={tekst.omschrijving}
                    onChange={(e) => updateText(index, "omschrijving", e.target.value)}
                    className="rounded-md border border-gray-400 w-full p-2 mt-2"
                  />
                </div>
                <div>
                  <label htmlFor={`steekwoord-${index}`} className="block mt-2">
                    Toelichting
                  </label>
                  <textarea
                    type="text"
                    id={`steekwoord-${index}`}
                    value={tekst.steekwoord}
                    onChange={(e) => updateText(index, "steekwoord", e.target.value)}
                    className="rounded-md border border-gray-400 w-full p-2 mt-2"
                  />
                </div>
              </div>
              <button className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white" onClick={() => removeText(index)}>
                Verwijder
              </button>
            </div>
          ))}
          <hr className="hr2" />
          <div className="flex gap-4">
            <button className="px-4 py-2 border rounded-md shadow-sm bg-green-500 text-white" onClick={saveData}>
              Opslaan
            </button>
            <button className="px-4 py-2 border rounded-md shadow-sm bg-white" onClick={() => navigate(-1)}>
              Terug
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}